
<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Gateway</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Gateway</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="Gateway">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-header">
              <h3 class="card-title" v-if="!is_edit">Create</h3>
              <h3 class="card-title" v-if="is_edit">Edit</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'GatewayList'}"><i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-6 required">
                  <label class="control-label">Name</label>
                  <input type="text" id="name" name="name" v-bind:class="{'has-error' : errors.has('name')}" v-validate="'required'" v-model="value.name" class="form-control name" placeholder="Name">
                  <div class="help text-danger" v-show="errors.has('name')">
                    {{ errors.first("name") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Pretty name</label>
                  <input type="text" id="pretty_name" name="pretty_name" v-bind:class="{'has-error' : errors.has('pretty_name')}" v-validate="'required'" v-model="value.pretty_name" class="form-control pretty_name" placeholder="Pretty name">
                  <div class="help text-danger" v-show="errors.has('pretty_name')">
                    {{ errors.first("pretty_name") }}
                  </div>
                </div>
                <div class="form-group col-6 ">
                  <label class="control-label">Description</label>
                  <input type="text" id="description" name="description" v-bind:class="{'has-error' : errors.has('description')}" v-model="value.description" class="form-control description" placeholder="Description">
                  <div class="help text-danger" v-show="errors.has('description')">
                    {{ errors.first("description") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Status</label>
                  <select class="form-control" style="width: 100%;" name="status" v-bind:class="{'has-error' : errors.has('status')}" v-validate="'required'" v-model="value.status" id="status">
                    <option :value="1">Active</option>
                    <option :value="0">Inactive</option>
                  </select>
                  <div class="help text-danger" v-show="errors.has('status')">
                    {{ errors.first("status") }}
                  </div>
                </div>
                <div class="form-group col-12 required">
                  <label class="control-label">Base URL</label>
                  <input type="text" id="base_url" name="base_url" v-bind:class="{'has-error' : errors.has('base_url')}" v-validate="'required'" v-model="value.base_url" class="form-control base_url" placeholder="Base URL">
                  <div class="help text-danger" v-show="errors.has('base_url')">
                    {{ errors.first("base_url") }}
                  </div>
                </div>
                <div class="offset-0 col-12">
                  <h4 class="site-heading my-3"><span>Additional Properties</span></h4>
                  <div class="form-row align-items-end" v-for="(key_val, i) in value.additional_properties_converted" :key="i">
                    <div class=" col-md-10">
                      <div class="row">
                        <div class="col">
                          <div class="form-group col-md-12">
                            <label class="control-label">Key</label>
                            <input type="text" name="key_val.key" v-model="key_val.key" class="form-control key_val.key" placeholder="Key">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group col-md-12">
                            <label class="control-label">Value</label>
                            <input type="text" name="key_val.value" v-model="key_val.value" class="form-control key_val.value" placeholder="Value">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-2">
                      <button type="button" class="btn btn-danger btn-sm float-right" @click="delete_key_value(i)"><i class="fa fa-trash"></i> Remove</button>
                    </div>
                  </div>
                  <div class="form-group col-12 text-right">
                    <button type="button" @click="add_key_value()" class="btn btn-success btn-sm"><i class="fa fa-plus"></i> New
                    </button>
                  </div>
                </div>

              </div>
            </div>
            <div class="card-footer">
              <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
              <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import { CBSPaymentApi, CBSCommonApi } from '@/services/api';

export default {
  name: 'GatewayForm',
  metaInfo: {
    title: 'Payment Gateway | Dotlines'
  },
  components: {
  },
  computed: {
    ...mapGetters(['profile'])
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
    value: {
      status: 1,
      additional_properties: {},
      additional_properties_converted: []
    },
    pre: {}
  }),
  methods: {
    handleSubmission () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true
          //key value convert
          this.value.additional_properties = {}
          this.value.additional_properties_converted.forEach((value, key) => {
            this.value.additional_properties[value.key] = value.value
          })
          if (this.is_edit) {
            this.value.updated_by = this.profile.id
            CBSPaymentApi.updateGetway(this.params.id, this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'GatewayList' })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$toaster.error(error.data);
              })
          } else {
            this.value.organization_ref_id = this.profile.organization_ref_id
            this.value.user_ref_id = this.profile.user_ref_id
            this.value.role_ref_id = this.profile.role_ref_id
            this.value.created_by = this.profile.id
            CBSPaymentApi.storeGetway(this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'GatewayList' })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.data)
              })
          }
        }
      });
    },
    getDetail: function (id) {
      CBSPaymentApi.showGetway(id)
        .then((item) => {
          this.value = item
          //convert as key value
          let obj = []
          _.forEach(this.value.additional_properties, function (value, key) {
            obj.push({ 'key': key, 'value': value })
          })
          this.$set(this.value, 'additional_properties_converted', obj)
          this.$delete(this.value, "additional_properties")
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    add_key_value: function () {
      this.value.additional_properties_converted.push({});
    },
    delete_key_value: function (index) {
      this.value.additional_properties_converted.splice(index, 1)
    },
    async initialize () {
      this.is_loading = true
      this.params = this.$route.params

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true
      }
      if (this.is_edit) {
        this.getDetail(this.params.id)
      }
      this.is_loading = false

    }
  },
  mounted () {
    this.initialize()
  }
}
</script>
